"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.Menu = function Menu() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true; // Open menu on click

    var $menuOpener = document.querySelector('.mobile-menu-trigger');

    if ($menuOpener) {
      $menuOpener.addEventListener('click', function () {
        var $menu = document.querySelector('.big-menu');
        $menu.classList.add('is-open');
        document.querySelector('body').style.overflow = 'hidden';
      });
    } // Close menu on click


    var $menuCloser = document.querySelector('.big-menu .close-trigger');

    if ($menuCloser) {
      $menuCloser.addEventListener('click', function () {
        var $menu = document.querySelector('.big-menu');
        $menu.classList.remove('is-open');
        document.querySelector('body').style.overflow = 'auto';
      });
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();