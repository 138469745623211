"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.HomeProjects = function HomeProjects() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var $homeProjects = document.querySelector('.home-projects .slider-container');

    if ($homeProjects) {
      var flkty = new Flickity($homeProjects, {
        // options
        cellAlign: 'left',
        contain: true,
        cellSelector: '.project-slide',
        pageDots: false,
        draggable: true,
        imagesLoaded: true
      });

      var changeIndex = function changeIndex(index) {
        var currentIndex = index + 1;
        var total = $homeProjects.querySelectorAll('.project-slide').length;
        var $currentIndexSpan = $homeProjects.querySelector('.slider-progress-container .current-index');
        var $progressBar = $homeProjects.querySelector('.slider-progress-container .progress-bar-inside');
        $currentIndexSpan.textContent = currentIndex;
        var progress = parseFloat(currentIndex * 100 / total, 10);
        $progressBar.style.width = "".concat(progress, "%");
      };

      flkty.on('change', function (index) {
        changeIndex(index);
      });
      changeIndex(0);
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();