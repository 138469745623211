"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.HomeSpit = function HomeSpit() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var $splitSliders = document.querySelectorAll('.home-split .content-slider');

    if ($splitSliders) {
      $splitSliders.forEach(function ($splitSlider) {
        var $slider = $splitSlider.querySelector('.slider-container');
        var flkty = new Flickity($slider, {
          // options
          cellAlign: 'left',
          contain: true,
          cellSelector: '.slider-item',
          pageDots: false
        });

        var changeIndex = function changeIndex(index) {
          var currentIndex = index + 1;
          var $currentIndexSpan = $splitSlider.querySelector('.slider-pagination .current-index');
          $currentIndexSpan.textContent = currentIndex;
        };

        flkty.on('change', function (index) {
          changeIndex(index);
        });
      });
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();