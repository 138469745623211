"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.MembersList = function MembersList() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var $membersItems = document.querySelectorAll('.members-list .member-container');

    if ($membersItems.length) {
      $membersItems.forEach(function ($memberItem) {
        var $trigger = $memberItem.querySelector('.member-item');
        var $popup = $memberItem.querySelector('.member-popup');
        var $closeTrigger = $popup.querySelector('.popup-close');
        $trigger.addEventListener('click', function (e) {
          e.preventDefault();
          $popup.classList.add('is-open');
          document.querySelector('body').style.overflow = 'hidden';
        });
        $closeTrigger.addEventListener('click', function (e) {
          e.preventDefault();
          $popup.classList.remove('is-open');
          document.querySelector('body').style.overflow = 'auto';
        });
      });
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();