"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.HomeHistory = function HomeHistory() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var $homeHistory = document.querySelector('.home-history .slider-wrapper');

    if ($homeHistory) {
      var flkty = new Flickity($homeHistory, {
        // options
        cellAlign: 'left',
        cellSelector: '.slider-item',
        pageDots: false,
        prevNextButtons: false,
        draggable: true
      });

      var changeIndex = function changeIndex(index) {
        var $sliderItems = $homeHistory.querySelectorAll('.slider-item');
        $sliderItems.forEach(function (item, itemIndex) {
          if (itemIndex < index) {
            item.classList.add('is-hidden');
          } else {
            item.classList.remove('is-hidden');
          }
        }); // Manage prev next button

        var $previousButton = document.querySelector('.home-history .slider-btn.previous');

        if (index === 0) {
          $previousButton.classList.add('is-hidden');
        } else {
          $previousButton.classList.remove('is-hidden');
        }

        var $nextButton = document.querySelector('.home-history .slider-btn.next');

        if (index === $sliderItems.length - 1) {
          $nextButton.classList.add('is-hidden');
        } else {
          $nextButton.classList.remove('is-hidden');
        }
      };

      flkty.on('change', function (index) {
        changeIndex(index);
      });
      var $sliderButtons = document.querySelectorAll('.home-history .slider-btn');
      console.log($sliderButtons);
      $sliderButtons.forEach(function (button) {
        button.addEventListener('click', function () {
          if (button.classList.contains('next')) {
            flkty.next();
          } else {
            flkty.previous();
          }
        });
      });
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();