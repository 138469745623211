"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.ContactPopup = function ContactPopup() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true; // Open popup on click

    var $popupOpeners = document.querySelectorAll('[data-contact]');

    if ($popupOpeners.length) {
      $popupOpeners.forEach(function ($popupOpener) {
        $popupOpener.addEventListener('click', function (e) {
          e.preventDefault();
          var $popup = document.querySelector('.contact-popup-container');
          $popup.classList.add('is-active');
          document.querySelector('body').style.overflow = 'hidden';
        });
      });
    } // Close popup on click


    var $popupCloser = document.querySelector('.contact-popup-container .close-trigger');

    if ($popupCloser) {
      $popupCloser.addEventListener('click', function () {
        var $popup = document.querySelector('.contact-popup-container');
        $popup.classList.remove('is-active');
        document.querySelector('body').style.overflow = 'auto';
      });
    } // Display phone number for desktop on popup


    var $phoneButton = document.querySelector('.contact-popup-container .popup-buttons .popup-button:first-child');

    if ($phoneButton) {
      $phoneButton.addEventListener('click', function (e) {
        if (window.innerWidth > 768) {
          e.preventDefault();
          var phoneText = $phoneButton.dataset.text.replace('tel:', '');
          $phoneButton.innerHTML = phoneText;
        }
      });
    } // Display phone number for desktop on footer


    var $footerPhoneButton = document.querySelector('.footer-buttons .footer-button:first-child');

    if ($footerPhoneButton) {
      $footerPhoneButton.addEventListener('click', function (e) {
        if (window.innerWidth > 768) {
          e.preventDefault();
          var phoneText = $footerPhoneButton.dataset.text.replace('tel:', '');
          $footerPhoneButton.innerHTML = phoneText;
        }
      });
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();