"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.ProjectsList = function ProjectsList() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var $projectsList = document.querySelector('.projects-container');

    if ($projectsList) {
      // Filters
      var $filtersForm = $projectsList.querySelector('.filters-container');
      var $filters = $filtersForm.querySelectorAll('.filter-select');
      $filters.forEach(function ($filter) {
        $filter.addEventListener('change', function (e) {
          $filtersForm.submit();
        });
      }); // Mobile Filters

      $filtersForm.addEventListener('click', function (e) {
        if (!$filtersForm.classList.contains('is-open')) {
          $filtersForm.classList.add('is-open');
        }
      }); // Open details

      var $projects = $projectsList.querySelectorAll('.project-container');
      $projects.forEach(function ($project) {
        var $trigger = $project.querySelector('.project-item');
        var $projectDetails = $project.querySelector('.project-popup');
        $trigger.addEventListener('click', function (e) {
          $projectDetails.classList.add('is-active');
          document.querySelector('body').style.overflow = 'hidden';
        });
        var $overlay = $projectDetails.querySelector('.popup-overlay');
        $overlay.addEventListener('click', function (e) {
          $projectDetails.classList.remove('is-active');
          document.querySelector('body').style.overflow = 'auto';
        });
        var $closeTrigger = $projectDetails.querySelector('.popup-close');
        $closeTrigger.addEventListener('click', function (e) {
          $projectDetails.classList.remove('is-active');
          document.querySelector('body').style.overflow = 'auto';
        });
      });
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();